html {
}
@media screen and (min-width: 768px) {
  html {
      font-size: 16px;
  }
}

@font-face {
  font-family: 'Noto Sans JP Bold';
  font-style: normal;
  src: url('../fonts/NotoSansJP-Bold.otf') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP Regular';
  font-style: normal;
  src: url('../fonts/NotoSansJP-Regular.otf') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP Light';
  font-style: normal;
  src: url('../fonts/NotoSansJP-Light.otf') format("woff");
  font-display: swap;
}

a {
  text-decoration: none;
}

button:focus {
  outline:0;
}
